@import "../ui/colors.css";


/* Hide scrollbar for Chrome, Safari and Opera */
.leftBarContainerInner::-webkit-scrollbar,
.navContainer::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .leftBarContainerInner,
  .navContainer {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }


.pageOuterContainer {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    image-rendering: pixelated;
    background-image: url('../ui/images/background_01.png');
    background-repeat: repeat;
    background-size: 25px;
    background-color: #ffe98d;
}
.pageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 24px);
    max-width: 1200px;

}

.bodyContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    /* background-color: bisque; */
}

.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100px; */
    /* background-color: lightblue; */
    margin-bottom: 8px;
    margin-top: 8px;
}

.logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-right: 12px;
    /* background-color: yellow; */
}

.footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    margin-top: 16px;
    margin-bottom: 16px;
    
    /* background-color: var(--color-primary); */
    /* color: var(--color-white); */
}

.footerContainerInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}


.rightColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    /* background-color: rgb(95, 165, 255); */

}

.mainContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* flex-grow: 1; */
    height: 100%;
    width: 100%;
    /* background-color: purple; */
    margin-right: 16px;
}

.leftBarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 30%;
    height: 100%;
    margin-right: 16px;
}

.leftBarContainerInner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* overflow-y: scroll; */
}


.socialLinksContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 230px;
    margin-bottom: 16px;
    /* background-color: orange; */
}
.heading3 {
    font-family: silkscreen, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
}

.tamagotchi {
    width: 100%;
    image-rendering: pixelated;
}

.pixelContainer {
    margin-bottom: 32px;
}

.rightBarContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}




.navContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    overflow-x: scroll;
    /* width: 100%; */
    /* height: 100%; */
    /* padding: 16px; */
}

.navItem {
    margin: 16px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--color-black);
    font-size: 15px;
    text-decoration: none;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    image-rendering: pixelated;
}

.logo {
    max-height: 100%;
    width: 200px;
    image-rendering: pixelated;
}

.socialLinksPositioning {
    position: absolute;
    top: 70px;
    left: 60px;
    bottom: 70px;
    right: 60px;
}

.tamagotchiLink {
    position: absolute;
    width: 40px;
    height: 40px;
    image-rendering: pixelated;
    background-size: contain;
    background-repeat: no-repeat;

}

.homeNavImage {
    background-image: url('../ui/icons/nav_icon_home.png');
}


.homeNavImage:hover {
    background-image: url('../ui/icons/nav_icon_home_outline.png');
}

.scrollNavImage {
    background-image: url('../ui/icons/nav_icon_scroll.png');
}


.scrollNavImage:hover {
    background-image: url('../ui/icons/nav_icon_scroll_outline.png');
}

.settingsNavImage {
    background-image: url('../ui/icons/nav_icon_settings.png');
}


.settingsNavImage:hover {
    background-image: url('../ui/icons/nav_icon_settings_outline.png');
}

.worldNavImage {
    background-image: url('../ui/icons/nav_icon_world.png');
}


.worldNavImage:hover {
    background-image: url('../ui/icons/nav_icon_world_outline.png');
}


.discordImage {
    background-image: url('../ui/icons/icon_discord.png');
}


.discordImage:hover {
    background-image: url('../ui/icons/icon_discord_hover.png');
}

.instagramImage {
    background-image: url('../ui/icons/icon_instagram.png');
}


.instagramImage:hover {
    background-image: url('../ui/icons/icon_instagram_hover.png');
}

.tiktokImage {
    background-image: url('../ui/icons/icon_tiktok.png');
}


.tiktokImage:hover {
    background-image: url('../ui/icons/icon_tiktok_hover.png');
}

.youtubeImage {
    background-image: url('../ui/icons/icon_youtube.png');
}


.youtubeImage:hover {
    background-image: url('../ui/icons/icon_youtube_hover.png');
}

.blueskyImage {
    background-image: url('../ui/icons/icon_bluesky.png');
}


.blueskyImage:hover {
    background-image: url('../ui/icons/icon_bluesky_hover.png');
}

.leftBarImage {
    width: 100px;
    height: 100px;
    padding: 4px;
}

.hoverAnimation {
    transition: transform 0.5s linear;
    transform: rotate(0deg)
}

.hoverAnimation:hover {
    animation: rotateImage 0.8s infinite; /* Infinite alternate rotation */
}

/* Keyframes for the continuous rotating animation */
@keyframes rotateImage {
    0% {
        transform: rotate(15deg); /* Start at +40 degrees */
    }
    50% {
        transform: rotate(15deg); /* Start at +40 degrees */
    }
    51% {
        transform: rotate(-15deg); /* End at -40 degrees */
    }
    100% {
        transform: rotate(-15deg); /* End at -40 degrees */
    }
}
    

/* Extra small devices (phones, 768px and down) */
@media only screen and (max-width: 768px) {
    .headerContainer {
        flex-direction: column;
    }

    .bodyContainer {
        flex-direction: column;
    }
    
    .navContainer {
        justify-content: center;
    }

    .navItem {
        font-size: 16px;
    }

    .logoContainer {
        margin-right: 0px;
        margin: 16px 0 16px 0;
    }

    .mainContentContainer {
        margin-right: 0px;
        margin-bottom: 16px;
    }

    .rightColumnContainer {
        width: 100%;
    }

    .leftBarContainer {
        width: 100%;
        height: 100%;
        margin-right: 0px;
        margin: 16px 0 16px 0px;
    }

    .leftBarContainerInner {
        flex-direction: row;
        overflow-x: scroll;
    }

}