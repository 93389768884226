

p {
    text-align: left;
    margin: 0;
}

li {
    text-align: left;
}

* {
    /* word-break:break-all */
    overflow-wrap: break-word;
}

.container {
    width: 100%;
}

.markdown img {
 max-width: 100%;
 max-height: 450px;
 height: auto;
 margin-top: 15px;
}

.containerInner {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 32px;
    box-sizing: border-box;
}

html.dark,
html.dark body,
.dark.container {
    background-color: var(--color-black);
}

.dark * {
    color: var(--color-white);
}

img {
    
}