/*
  This variable will be available everywhere,
  because every element is a descendant of
  the HTML tag:
*/
html {
    --color-black: #151315;
    --color-white: #ffffff;

    --color-grey4: #4f4f4f;
    --color-grey3: #292c33;
    --color-grey2: #424a53;
    --color-grey1: #646d77;

    --color-white4: #87a6ac;
    --color-white3: #add0d7;
    --color-white2: #cdeaef;
    --color-white1: #e4fbff;

    --color-black4: #b8b8b8;
    --color-black3: #a1a1a1;
    --color-black2: #5d5d5d;
    --color-black1: #353535;

    --color-bg: #ffffff;
    --color-bg-dark: #bbb3bb;

    --color-primary: #5498FF;
    --color-secondary: #EA8941;
    
  }