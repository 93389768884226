@import "./colors.css";

.sectionContainer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;
}

.sectionInnerContainer {
    max-width: 900px;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sectionContatinerColumns {
    flex-direction: row;
    align-items: center;

}

.sectionContatinerLeft {
    flex-direction: row;
    justify-content: space-between;
}

.sectionContatinerLeft > img {
    padding-right: 32px;
}

.sectionContatinerRight {
    flex-direction: row-reverse;
    justify-content: space-between;
}

.sectionContatinerRight > img {
    padding-left: 32px;
}

.collabsibleTitle {
    /* font-family: 'zxSpectrum', sans-serif; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    /* background-color: var(--color-silver-2); */
    border-radius: 16px;
    padding: 4px 16px;
    cursor: pointer;
    box-sizing: border-box;
}
.collabsibleIcon {
    width: 30px;
    height: 30px;
    transition: transform 0.3s ease-out;
}

.collabsibleDivider {
    width: 100%;
    height: 1px;
}
/* CHEVRONS */

.chevron {
    padding-top:20px;
  }
  
  .chevron {  
    text-align:center;
    padding: 0 10px;
    width: auto;
    display: inline-block;
    border: none; 
    
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    
  }
  
  .chevron span {
    background-color: var(--color-white);
    border: solid 1px var(--color-white);
    display: block;
    
    height: 15px;
    width: 2px; 

    border-radius: 2px;
    
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    
  }
  

  
  /* Down */
  
  .chevron1.chevronstartdown span {
    display:inline-block;
    margin-right: 2.9px;
    margin-left: 2.9px;
  }
  .chevron1.chevronstartdown span:nth-child(1) {
    transform: rotate(-45deg);
  }
  .chevron1.chevronstartdown span:nth-child(2) {
    transform: rotate(45deg);
  }
  .chevron1.active.chevronstartdown span:nth-child(1) {
    transform: rotate(-135deg);
  }
  .chevron1.active.chevronstartdown span:nth-child(2) {
    transform: rotate(135deg);
  }
  
  .chevron1.chevron-start-up,
  .chevron1.chevronstartdown {
    width: auto;
  }


/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
    .sectionInnerContainer {
        flex-direction: column;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

