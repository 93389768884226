@import "../ui/colors.css";

.homeHeader {
    color: var(--color-bg);
}

.heading1 {
    font-size: 50px;
    letter-spacing: -4px;
    font-family: silkscreen, 'Roboto', sans-serif;
    text-align: center;
    padding: 32px 0 0 0;
}

.heading2 {
    font-size: 30px;
    letter-spacing: -3px;
    font-family: silkscreen, 'Roboto', sans-serif;
    text-align: center;
    padding: 16px 0;
}

.subHeading {
    font-family: cnc, 'Roboto', sans-serif;
    font-size: 25px;
}

.heading3 {
    font-family: silkscreen, 'Roboto', sans-serif;
    letter-spacing: -2px;
    font-size: 25px;
    padding: 16px 0;
    text-align: center;
}

.topPadding {
    height: 320px;
}

.section {
    text-align: left;
}

.splitSection {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: row;
}

.sectionGridContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

.sectionGridContainerInner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 16px;
}

.sectionGridImage {
    width: 100%;
}

.sectionImage {
    width: 100%;
}

.sectionImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.sectionImageShort {
    max-height: 70px;
}

.sectionImageSmall {
    width: 20%;
}

.sectionImageTall {
    width: 70%;
    image-rendering: pixelated;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.smallIcon {
    height: 16px;
    width: 16px;
    padding-right: 12px;
    opacity: 1;
}

.smallIcon:hover {
    opacity: 0.5;
    cursor: pointer;
}

.iconLink {
    display: flex;
    align-items: center;
}

html {
    --gridWidth: 150px;
}

.gridTitle {
    position: absolute;
    top: var(--gridWidth);
    left: calc(var(--gridWidth) * 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.gridTitleRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.gridTitleCard {
    font-size: 70px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.grid {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.gridImages {
    width: var(--gridWidth);
    height: var(--gridWidth);
}

.redacted {
    /* color: var(--color-bg-dark); */
    letter-spacing: -1px;
    overflow-wrap: anywhere;
}

.sectionDropdownInner {
    width: 100%;
    padding: 8px;
    padding-top: 0;
    padding-bottom: 16px;
}

.downloadButton {
    padding: 45px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hoverAnimation {
    transition: transform 0.5s linear;
    transform: rotate(0deg)
}

.hoverAnimation:hover {
    animation: rotateImage 0.8s infinite; /* Infinite alternate rotation */

}

/* Keyframes for the continuous rotating animation */
@keyframes rotateImage {
    0% {
        transform: rotate(5deg); /* Start at +40 degrees */
    }
    50% {
        transform: rotate(5deg); /* Start at +40 degrees */
    }
    51% {
        transform: rotate(-5deg); /* End at -40 degrees */
    }
    100% {
        transform: rotate(-5deg); /* End at -40 degrees */
    }
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
    .sectionGridContainer {
        flex-direction: column;
    }
    .sectionGridImage, .sectionImageSmall {
        width: 50%;
    }

    .sectionImageTall {
        width: 30%;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .heading1 {
        font-size: 40px;
    }
    
    .subHeading {
        font-size: 30px;
    }
    
}