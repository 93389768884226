@import "../../../ui/colors.css";


p {
    text-align: left;
    margin: 0;
}

li {
    text-align: left;
}

* {
    /* word-break:break-all */
    overflow-wrap: break-word;
}

.container {
    width: 100%;
}

.markdown img {
 max-width: 100%;
 width: 100%;
 height: auto;
 margin-top: 15px;
}

.containerInner {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 32px;
    box-sizing: border-box;
}

html.dark,
html.dark body,
.dark.container {
    background-color: var(--color-black);
}

.dark * {
    color: var(--color-white);
}

.itemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.itemImage {
    width: 100px;
    height: 100px;
    background-color: aquamarine;
}
.itemTitle {
    color: black;
    font-weight: bold;
    font-size: 12px;
}
.itemDetails {
    color: black;
    font-size: 12px;
    
}

.rarityTable {
    display: flex;
    flex-direction: column;
    background-color: aqua;
}

.rarityTableRow {
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}