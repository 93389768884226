/* @import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Silkscreen:wght@400;700&display=swap'); */

@font-face {
  font-family: 'zxSpectrum';
  src: local('zxSpectrum'), url(./ui/fonts/zx-spectrum.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'pkmn';
  src: local('pkmn'), url(./ui/fonts/PKMN_RBYGSC_2.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'silkscreen';
  src: local('silkscreen'), url(./ui/fonts/slkscr.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'cnc';
  src: local('cnc'), url(./ui/fonts/cnc.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


body, html, #root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: cnc, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

h1, h2, h3, h4 {
  font-family: silkscreen, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 300;
}


h1 { 
  font-size: 30px;
  font-weight: 600;
}
h2 {
  font-size: 30px;
  font-weight: 600;
}
h3 {
  font-size: 22px;
}
h4 { 
  font-size: 15px;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


